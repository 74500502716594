let expFuc = (exp, value, callback, errorMsg, emptyMsg) => {
    let expRule = exp
    if (value) {
        if (!expRule.test(value)) {
            callback(new Error(errorMsg))
        } else {
            callback()
        }
    } else {
        callback(new Error(emptyMsg))
    }
}
// 身份证校验
let identExpFuc = (rule, value, callback) => {
    let numExp = /(^\d{15}$)|(^\d{17}([0-9]|X|x)$)/
    let errorMsg = "请输入正确的证件号"
    let emptyMsg = "请输入证件号"
    expFuc(numExp, value, callback, errorMsg, emptyMsg)
}
// 邮箱校验
let emailExpFuc = (rule, value, callback) => {
    let numExp = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    let errorMsg = '请输入正确的邮箱'
    let emptyMsg = '请输入邮箱'
    expFuc(numExp, value, callback, errorMsg, emptyMsg)
}
// 手机号校验
let phoneExpFuc = (rule, value, callback) => {
    // let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    let reg = /^1(([3][0-9])|([4][7])|([5][0|1|2|3|5|6|7|8|9])|([6][6])|([7][0-9])|([8][0-9])|([9][8-9]))[0-9]{8}$/
    if (rule.field == "mobileNum") {
        sessionStorage.setItem('mobileNum', value)
    } else if (rule.field == "emergencyPhone") {
        sessionStorage.setItem('emergencyPhone', value)
    }
    let mobileNum = sessionStorage.getItem('mobileNum')
    let emergencyPhone = sessionStorage.getItem('emergencyPhone')
    if (value) {
        if (!reg.test(value)) {
            callback(new Error('请输入正确的手机号'))
        } else {
            if (mobileNum == emergencyPhone) {
                callback(new Error('紧急联系电话与手机号不能相同'))
            } else {
                callback()
            }
        }
    } else {
        callback(new Error('请输入号码'))
    }
}
// 姓名校验
let nameExpFuc = (rule, value, callback) => {
    let reg = /^[\u4E00-\u9FA5A-Za-z]+$/
    if (rule.field == "name") {
        sessionStorage.setItem('name', value)
    } else if (rule.field == "emergencyContact") {
        sessionStorage.setItem('emergencyContact', value)
    }
    let name = sessionStorage.getItem('name')
    let emergencyContact = sessionStorage.getItem('emergencyContact')
    if (value) {
        if (!reg.test(value)) {
            callback(new Error('请输入正确的姓名'))
        } else {
            if (name == emergencyContact) {
                callback(new Error('紧急联系人与姓名不能相同'))
            } else {
                callback()
            }
        }
    } else {
        callback(new Error('请输入姓名'))
    }
}
// 所在地校验
let locationExpFuc = (rule, value, callback) => {
    if(!value) {
        callback(new Error('请输入收货地址！'));
    } else if(value.length > 200) {
        callback(new Error('详细地址长度在200以内'));
    } else {
        callback()
    }
}
// 其他校验
let otherExpFuc = (rule, value, callback) => {
    if (!value) {
        callback(new Error('内容不能为空'));
    } else {
        callback()
    }
}
export default {
    data() {
        return {
            formControl: [],
            formRules: {},
        }
    },
    methods: {
        formatFormList() {
            this.formList.forEach((item) => {
                let aObject = {
                    type: item.formType,
                    label: item.name,
                    key: item.key,
                    placeholder: item.formType == 'text' ? `请填写${item.name}` : `请选择${item.name}`,
                    id: item.id,
                    dynamic: item.dynamic,
                    isRequired: item.required
                }
                // form rules
                let isRequired = item.required == undefined ? false : item.required
                let aRules
                if (item.key != 'location') {
                    aRules = [{
                        required: isRequired,
                        trigger: 'blur'
                    }]
                } else {
                    aRules = [{
                        required: isRequired,
                        trigger: 'change'
                    }]
                }
                // 需要特殊处理的规则
                if (item.key == 'name' || item.key == 'emergencyContact') {
                    // 姓名
                    aRules[0]['validator'] = nameExpFuc
                } else if (item.key == 'emergencyPhone' || item.key == 'mobileNum') {
                    // 电话号码
                    aRules[0]['validator'] = phoneExpFuc
                } else if (item.key == 'email') {
                    // 邮箱
                    aRules[0]['validator'] = emailExpFuc
                } else if (item.key == 'shouhuodizhi') {
                    // 详细地址
                    aRules[0]['validator'] = locationExpFuc
                } else if (item.key == 'cardType') {
                    aRules[0]['validator'] = (rule, value, callback) => {
                        if(!value) {
                            callback(new Error('请选择证件类型'));
                        } else {
                            sessionStorage.setItem('cardType', value)
                            callback()
                        }
                    }
                } else {
                    // 其他
                    if (isRequired) {
                        if(item.key != 'location') {
                            aRules[0]['validator'] = (rule, value, callback) => {
                                if (!value) {
                                    let message
                                    if (item.formType == 'text') {
                                        message = `请填写${item.name}`
                                    } else if (item.formType == 'radio') {
                                        message = `请选择${item.name}`
                                    } else if (item.formType == 'select' && item.key != 'location') {
                                        message = `请选择${item.name}`
                                    }
                                    callback(new Error(message));
                                } else {
                                    callback()
                                }
                            }
                        }
                    }
                }
                let singleRule = {}
                singleRule[item.key] = aRules
                if (item.formType === 'text') {}
                if (item.formType === 'radio') {
                    Object.assign(aObject, {
                        options: item.secondList.map(oItem => {
                            return {
                                label: oItem.name,
                                value: oItem.formValue
                            }
                        })
                    })
                }
                if (item.formType === 'select') {
                    if (item.secondList) {
                        Object.assign(aObject, {
                            options: item.secondList.map(oItem => {
                                return {
                                    label: oItem.name,
                                    value: oItem.formValue
                                }
                            })
                        })
                    } else {
                        Object.assign(aObject, {
                            options: []
                        })
                    }
                    
                }
                this.formControl.push(aObject)
                this.formRules[item.key] = singleRule[item.key]
            })
            // console.log(this.formRules)
        }
    },
}