<template>
  <div class="order_box">
    <!-- 加载动画 -->
    <transition name="fade">
      <loading v-if="isLoading"></loading>
    </transition>
    <Header></Header>
    <div class="order_cont">
      <div class="container">
        <div class="apply_info clearfix">
          <span class="tit">报名人信息</span>
          <!-- 有报名人信息时 -->
          <template v-if="userList.length">
            <ul
              class="apply_lists clearfix"
              :style="
                userList.length <= 8
                  ? 'height:auto'
                  : slidePlayer
                  ? 'height:auto'
                  : 'height:140px;overflow:hidden;'
              "
            >
              <li
                :class="['list f_left text_over', { act: item.isSelect }]"
                v-for="(item, index) in userList"
                :key="index"
                @click="chooesApplyer(index, item)"
              >
                {{ item.name }}
                <span
                  class="editor"
                  @click.stop="editUserInfo(item)"
                  v-if="isEnoughArr[index]"
                  >编辑</span
                >
                <span class="editor" @click.stop="editUserInfo(item)" v-else
                  >信息不完善&nbsp;点此编辑</span
                >
                <span class="dafault" v-if="item.isDefault == 1"
                  >默认报名人</span
                >
              </li>
            </ul>
            <div class="apply_bot">
              <span
                class="slide clearfix"
                v-show="userList.length > 8 && isSlide"
                @click="slideUser"
              >
                <span class="f_left">展开全部</span>
                <i class="slide_icon f_left"></i>
              </span>
              <span
                class="add clearfix"
                @click="addUserInfo"
                v-show="userList.length"
              >
                <i class="add_icon f_left"></i>
                添加其他报名人信息
              </span>
            </div>
          </template>
          <!-- 无报名人信息时 -->
          <template v-else>
            <button class="addInfo f_left" @click="addUserInfo">
              ＋添加报名人信息
            </button>
          </template>
        </div>
        <div class="choosed_cont">
          <span class="tit">已选用户</span>
          <ul class="chooseds">
            <li
              class="list"
              v-for="(item_select, sel_index) in choosedArr"
              :key="sel_index"
            >
              <div class="info_show">
                <button
                  class="checkuser f_left"
                  @click="deleteInfo(sel_index, item_select)"
                ></button>
                <span class="info_txt name"
                  >姓名：{{ item_select.name | dealname }}</span
                >
                <span class="info_txt sex_info"
                  >性别：{{ item_select.sex }}</span
                >
                <span class="info_txt num_info"
                  >手机号：{{ item_select.mobileNum }}</span
                >
                <span class="info_txt idnum"
                  >证件号：{{ item_select.cardId }}</span
                >
                <div class="edit">
                  <span @click.stop="editUserInfo(item_select)">编辑</span>
                  <span
                    class="del"
                    v-if="additionalList.length"
                    @click="slideAdditional(item_select, sel_index)"
                    >附加项</span
                  >
                </div>
                <span class="dprice f_right"
                  >￥<span class="num" v-if="eventData">{{
                    dfee | dealcount
                  }}</span>
                </span>
              </div>
              <div class="additions_cont" v-if="hasAdditional === sel_index">
                <!-- <ul class="additions" :style="{height: additionalHeight}">
                                    <li
                                      class="addition_item" 
                                      v-for="(item,index) in additionalList" 
                                      :key="`${index}_${sel_index}`"
                                      @click="chooseAddition(item, item_select, `${index}_${sel_index}`)"
                                    >
                                        <img src="../../assets/icons/checked.png" class="checkuser f_left" 
                                        v-if="item.check">
                                        <img src="../../assets/icons/check.png" class="checkuser f_left" v-else>
                                        <span class="addition_info">{{item.name}}</span>
                                        <span class="addition_info">{{item.fee|dealcount}}元/人</span>
                                    </li>
                                </ul> -->
                <ul class="additions" :style="{ height: additionalHeight }">
                  <li
                    class="addition_item"
                    v-for="(item, index) in item_select.additional"
                    :key="`${index}_${sel_index}`"
                    @click="
                      chooseAddition(item, item_select, `${index}_${sel_index}`)
                    "
                  >
                    <img
                      src="../../assets/icons/checked.png"
                      class="checkuser f_left"
                      v-if="item.check"
                    />
                    <img
                      src="../../assets/icons/check.png"
                      class="checkuser f_left"
                      v-else
                    />
                    <span class="addition_info">{{ item.name }}</span>
                    <span class="addition_info"
                      >{{ item.fee | dealcount }}元/人</span
                    >
                  </li>
                </ul>
                <template v-if="additionalList.length > 1">
                  <span
                    class="slide f_right"
                    v-if="hasAdditional == sel_index && isSlideAddition"
                    @click="slideAdditions()"
                  >
                    <span class="f_left">展开全部</span>
                    <i class="slide_icon f_left"></i>
                  </span>
                </template>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="pay_cont clearfix">
        <span class="f_left player">共{{ choosedArr.length }}位参赛者</span>
        <div class="f_right pat_right">
          <span class="tprice f_left"
            >合计：￥<span>{{ total_fee | dealcount }}</span></span
          >
          <button class="pay apply f_left" @click="pay_handle">确认支付</button>
        </div>
      </div>
    </div>
    <info-dialog
      :show.sync="isShowDialog"
      :formControl="formControl"
      :formRules="formRules"
      :updateFormData="updateFormData"
      :entryId="entryId"
      :otherFormData="otherFormData"
      @closeDialog="closeDialog"
    ></info-dialog>
    <Footer :footerBgColor="'#fff'"></Footer>
  </div>
</template>

<script>
import { Order, personalCenter } from "../../config/request";
import { constants, truncate, truncateSync } from "fs";
import dyncForm from "@/config/mixin";
import { isError, debuglog } from "util";
export default {
  name: "order",
  mixins: [dyncForm],
  filters: {
    //价钱显示两位小数
    dealcount(v) {
      return Number(v).toFixed(2);
    },
    // 姓名显示最多6个
    dealname(v) {
      return v.length > 6 ? v.substr(0, 6) + "..." : v;
    },
  },
  data() {
    return {
      entryId: "", //参赛类别id
      eventId: "", //赛事id
      userInfo: null, //用户信息
      checked: true,
      isSlide: true, //是否显示展开
      slidePlayer: false, //展开报名人信息
      userList: [], //报名人信息列表
      choosedArr: [], //已选列表
      fee: "", //所选赛事单价
      dfee: "", //赛事价
      total_fee: "",
      eventData: {}, //所选赛事信息
      additionalList: [], //附加项列表
      choosedAddition: [], //所选附加项
      hasAdditional: null, //是否有附加项
      additionalHeight: "50px",
      isSlideAddition: true, //是否显示附加项展开
      isShowDialog: false, //编辑报名人弹框
      chooseItem_num: "", //选择报名人的下标
      // formData1:[],//动态表单内容
      formRules: {}, //规则
      updateFormData: {}, //编辑报名人信息
      isEnoughArr: [],
      isLoading: true,
      otherFormData: [], // 表单额外数据
    };
  },
  methods: {
    // 获取报名人信息
    getPlayers() {
      Order.users({
        mobile: this.userInfo.mobile,
        entryId: "",
      }).then((res) => {
        // console.log(res)
        this.isLoading = false;
        if (res.code == 1 && res.list) {
          personalCenter
            .getDynamicForms({
              entryId: this.entryId,
            })
            .then((res1) => {
              // console.log(res1)
              // this.formData1 = res1.eventList

              let requiredArr = [];
              // 获取必填字段
              res1.eventList &&
                res1.eventList.forEach((item) => {
                  if (item.required) {
                    requiredArr.push(item.key);
                  }
                });
              console.log("requiredArr", requiredArr);

              res.list.forEach((userItem) => {
                let isEnough;
                // 取出对象里所有的key
                let keyArr = Object.keys(userItem);
                // 判断这个对象中是否包含所有必填的字段key
                let resultKey = requiredArr.every((key) => {
                  return keyArr.includes(key);
                });
                if (resultKey) {
                  // 包含所有key
                  // 判断包含时，所填的值是否为空
                  let resultValue = requiredArr.every((key) => {
                    return userItem[key];
                  });
                  isEnough = resultValue ? true : false;
                } else {
                  // 不包含某些key
                  isEnough = false;
                }
                isEnough
                  ? this.isEnoughArr.push(true)
                  : this.isEnoughArr.push(false);
                let Item = {
                  check: false,
                  additional: JSON.parse(JSON.stringify(this.additionalList)),
                  ...userItem,
                };
                this.userList.push(Item);
              });
              // console.log(this.userList)

              this.userList.forEach((item, index) => {
                // 编辑后保存保持原来的已选用户列表不清空
                if (sessionStorage.getItem("choosedIdArr")) {
                  let IdArr = JSON.parse(
                    sessionStorage.getItem("choosedIdArr")
                  );
                  let choosedArr = [];
                  IdArr.forEach((userId) => {
                    if (userId == item.id) {
                      item.isSelect = true;
                      this.choosedArr.push(item);
                      this.total_fee = this.choosedArr.length * this.dfee;
                      console.log(choosedArr);
                    }
                  });
                } else {
                  // 有默认报名人且信息完整，展示默认报名人
                  if (item.isDefault == 1 && this.isEnoughArr[index]) {
                    item.isSelect = true;
                    this.choosedArr.push(item);
                    this.total_fee = this.choosedArr.length * this.dfee;
                  }
                }
              });
              if (sessionStorage.getItem("choosedIdArr")) {
                sessionStorage.removeItem("choosedIdArr");
              }
            });
        }
      });
    },
    // 展开报名人信息
    slideUser() {
      this.slidePlayer = true;
      this.isSlide = false;
    },
    // 获取附加项和赛事信息和表单
    getAdditions() {
      Order.additions({
        entryId: this.entryId,
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.eventData = res.objectData;
          this.dfee = res.objectData.fee;
          if (res.objectData.additionalList) {
            // this.additionalList = res.objectData.additionalList;
            res.objectData.additionalList.forEach((item) => {
              let newItem = {
                check: false,
                ...item,
              };
              this.additionalList.push(newItem);
            });
            // console.log( this.additionalList)
          }
        }
      });
    },
    // 选择报名人
    chooesApplyer(index, item) {
      if (this.isEnoughArr[index]) {
        let idIndex = this.choosedArr.indexOf(item);
        // console.log(idIndex)
        if (idIndex >= 0) {
          this.choosedArr.splice(idIndex, 1);
          item.isSelect = false;
        } else {
          this.choosedArr.push(item);
          item.isSelect = true;
        }

        console.log(this.choosedArr);
        let additionFee = 0;
        this.choosedArr.forEach((chooseItem) => {
          chooseItem.additional.forEach((additionalItem) => {
            if (additionalItem.check) {
              additionFee += additionalItem.fee;
            }
          });
        });
        this.total_fee = this.choosedArr.length * this.dfee + additionFee;
        // this.choosedAddition = [];
      } else {
        this.$alert("请完善该报名人信息!", "消息提示", {
          dangerouslyUseHTMLString: true,
        });
      }
    },
    //删除当前报名人
    deleteInfo(index, item) {
      let idIndex = item.id;
      this.choosedArr.splice(index, 1);
      this.userList.forEach((item) => {
        if (item.id == idIndex) {
          item.isSelect = false;
        }
      });
      for (let i = 0; i < this.choosedAddition.length; i++) {
        if (this.choosedAddition[i].user == idIndex) {
          this.choosedAddition.splice(i, 1);
        }
      }
      let additionFee = 0;
      this.choosedAddition.length > 0 &&
        this.choosedAddition.forEach((additionItem) => {
          additionFee += additionItem.fee;
        });
      this.total_fee = this.choosedArr.length * this.dfee + additionFee;
      // this.choosedAddition = [];
      console.log(this.choosedAddition);
    },
    //点击附加项出现
    slideAdditional(item, index) {
      this.hasAdditional = index;
    },
    //展开附加项
    slideAdditions() {
      this.additionalHeight = "auto";
      this.isSlideAddition = false;
    },
    // 选择附加项
    chooseAddition(item, Item, index) {
      // console.log(item)
      // console.log(subItem)
      item.check = !item.check;
      console.log(item);
      var obj = {
        key: index,
        id: item.id,
        user: Item.id,
        fee: item.fee,
      };
      // console.log(obj)
      // 取消选择删除该信息
      if (!item.check) {
        for (var i = 0; i < this.choosedAddition.length; i++) {
          if (this.choosedAddition[i].key == obj.key) {
            this.choosedAddition.splice(i, 1);
            this.total_fee -= item.fee;
            return;
          }
        }
      } else {
        this.total_fee += item.fee;
        this.choosedAddition.push(obj);
      }
      console.log(this.choosedAddition);
    },
    // 确认支付，下单,进入支付页面
    pay_handle() {
      let param_1 = {};
      console.log(this.choosedArr);
      // 已选择报名人
      for (let i = 0; i < this.choosedArr.length; i++) {
        let obj = this.choosedArr[i];
        let cardId = obj.cardId;
        let id = obj.id;
        param_1[obj.cardId] = obj.id;
      }
      //已选附加项
      var AdditionalList = [];
      var AdditionalObj = {};
      for (var i = 0; i < this.choosedAddition.length; i++) {
        var value = this.choosedAddition[i];
        if (!AdditionalObj[value.id]) {
          AdditionalList.push({
            id: value.id,
            users: [value.user],
          });
          AdditionalObj[value.id] = value;
        } else {
          for (var j = 0; j < AdditionalList.length; j++) {
            var obj = AdditionalList[j];
            if (obj.id == value.id) {
              obj.users.push(value.user);
              break;
            }
          }
        }
      }
      // console.log(AdditionalList)
      let additionals = this.choosedAddition.length
        ? JSON.stringify(AdditionalList)
        : "";
      let param = JSON.stringify(param_1);
      // console.log(param)
      let entryId = this.entryId;
      let mobile = this.userInfo.mobile;
      let sessionid = this.userInfo.sessionid;
      let from = "frompc";
      // 下单
      if (this.choosedArr.length) {
        Order.getorder({
          entryId: entryId,
          sessionid: sessionid,
          from: from,
          mobile: mobile,
          param: param,
          additionals: additionals,
        }).then((res) => {
          console.log(res);
          if (res.code == 906) {
            // 下单成功
            this.$router.push({
              path: "/pay",
              query: {
                entryId: entryId,
                outTradeNo: res.outTradeNo,
                mobile: mobile,
              },
            });
          } else if (res.code == 900) {
            let str = this.getStr(res.list);
            this.$alert(str + " 已报名该项目", "消息提示", {
              dangerouslyUseHTMLString: true,
            });
            if (res.data && res.data.has_not_pay) {
              this.$router.push({
                path: "/personalCenter",
                query: {
                  componentName: "myEnrollRecord",
                },
              });
            }
          } else {
            this.$alert(res.msg, "消息提示", {
              dangerouslyUseHTMLString: true,
            });
          }
        });
      } else {
        this.$alert("请勾选报名人", "消息提示", {
          dangerouslyUseHTMLString: true,
        });
      }
    },
    // 拼接已报名人的姓名
    getStr(list) {
      var userlist = this.userList;
      var str = "";
      for (var i = 0; i < list.length; i++) {
        for (var j = 0; j < userlist.length; j++) {
          if (userlist[j].id == list[i]) {
            str = str + userlist[j].name + " ";
          }
        }
      }
      return str;
    },
    // 获取动态表单
    getEventDyncList() {
      personalCenter
        .getDynamicForms({
          entryId: this.entryId,
        })
        .then((res) => {
          if (res.code == 1 && res.eventList) {
            if (res.selects.length > 0) {
              this.otherFormData = res.selects;
            }
            this.formList = res.eventList;
            this.formatFormList();
          }
        });
    },
    // 添加报名人信息
    addUserInfo() {
      this.isShowDialog = true;
      this.saveChoosed();
    },
    // 编辑报名人信息
    editUserInfo(item) {
      this.saveChoosed();
      personalCenter
        .getUserInfo({
          personId: item.id,
          entryId: this.entryId,
        })
        .then((res) => {
          // console.log(res)
          this.updateFormData = res.objectData;
          this.isShowDialog = true;
        });
    },
    // 关闭弹框
    closeDialog() {
      this.isShowDialog = false;
      this.updateFormData = {};
    },
    // 编辑和添加之后保存已选用户的信息
    saveChoosed() {
      if (this.choosedArr.length) {
        let IdArr = [];
        // console.log(this.choosedArr)
        this.choosedArr.forEach((item) => {
          let userId = item.id;
          IdArr.push(userId);
        });
        // alert(IdArr)
        sessionStorage.setItem("choosedIdArr", JSON.stringify(IdArr));
      }
    },
  },
  mounted() {
    // 获取地址栏id
    if (this.$route.query.eventId && this.$route.query.entryId) {
      this.eventId = this.$route.query.eventId;
      this.entryId = this.$route.query.entryId;
    }
    // 获取所选赛事信息
    this.getAdditions();
    this.getEventDyncList();
    // 获取用户信息和所选赛事单价
    if (
      sessionStorage.getItem("userInfo") &&
      sessionStorage.getItem("eventType")
    ) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.fee = JSON.parse(sessionStorage.getItem("eventType")).fee;
      this.getPlayers();
    } else {
      this.$router.push({
        path: "/",
      });
    }
  },
};
</script>

<style scoped>
@import "./style/orderPay.css";
</style>
